<template>
  <div>
    <b-modal id="modal-create-customer" modal-class="modal-import" title="Cadastrar cliente" centered hide-footer
      hide-header-close no-close-on-backdrop>
      <b-form ref="formCreateUser" @submit.prevent="submitCreate">
        <div class="form-row">
          <div class="col-md-8">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Nome completo
              </label>
              <input type="text" id="full_name" v-model="item.full_name" minlength="5" name="full_name"
                class="form-control" :class="{ 'is-invalid': $v.item.full_name.$error }" />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="">
                CPF
              </label>
              <input v-mask="['###.###.###-##']" type="tel" v-model="item.cpf" name="cpf" placeholder="000.000.000-00"
                class="form-control" />
            </div>
          </div>

          <div class="col-md-8">
            <div class="form-group mb-md-0">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                E-mail
              </label>
              <input type="email" id="full-name" v-model="item.email" class="form-control"
                :class="{ 'is-invalid': $v.item.email.$error }" />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group mb-md-0">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Celular
              </label>
              <input v-mask="['(##) #####-####']" type="tel" v-model="item.cellphone" name="cellphone"
                placeholder="(00) 00000-0000" class="form-control" :class="{ 'is-invalid': $v.item.cellphone.$error }" />
            </div>
          </div>
        </div>

        <h5 class="mt-2">Endereço</h5>
        <p class="mb-2">Caso você tenha, preencha o endereço do cliente</p>
        <div class="form-row">
          <div class="col-md-3">
            <label for=""> CEP </label>
            <div class="form-group">
              <input @keyup="searchCep()" v-mask="['#####-###']" type="text" v-model="item.cep" class="form-control" />
            </div>
          </div>
          <div class="col-md-3">
            <label for=""> Número </label>
            <div class="form-group">
              <input type="text" v-model="item.number" class="form-control" />
            </div>
          </div>
          <div class="col-md-3">
            <label for=""> Complemento </label>
            <div class="form-group">
              <input type="text" v-model="item.complement" class="form-control" />
            </div>
          </div>
          <div class="col-md-3">
            <label for=""> UF </label>
            <div class="form-group">
              <input type="text" v-model="item.uf" class="form-control" />
            </div>
          </div>
          <div class="col-md-4">
            <label for=""> Endereço </label>
            <div class="form-group">
              <input type="text" v-model="item.address" class="form-control" />
            </div>
          </div>
          <div class="col-md-4">
            <label for=""> Bairro </label>
            <div class="form-group mb-md-0">
              <input type="text" v-model="item.neighborhood" class="form-control" />
            </div>
          </div>
          <div class="col-md-4">
            <label for=""> Cidade </label>
            <div class="form-group mb-md-0">
              <input type="text" v-model="item.city" class="form-control" />
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end mt-2">
          <div class="col-2">
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" block
              @click="$bvModal.hide('modal-create-customer')">
              Fechar
            </b-button>
          </div>
          <div class="col-3">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-2" type="submit" block
              :disabled="submitted">
              <div v-if="submitted">
                <b-spinner small variant="light" />
              </div>
              <div v-else>Cadastrar</div>
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BButton,
  BSpinner
} from "bootstrap-vue";
import { required, minLength, email } from "vuelidate/lib/validators";
import axios from "axios";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BModal,
    BForm,
    BButton,
    BSpinner
  },
  directives: {
    Ripple,
  },
  data: () => ({
    item: {
      cpf: "",
      full_name: "",
      email: "",
      cellphone: "",
      address: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      cep: "",
    },
    submitted: false,
  }),
  validations: {
    item: {
      full_name: {
        required,
        minLength: minLength(5),
      },
      cellphone: {
        required,
        minLength: minLength(11),
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    searchCep() {
      if (this.item.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.item.cep}/json/`)
          .then((res) => {
            const data = res.data;
            this.item.address = data.logradouro;
            this.item.neighborhood = data.bairro;
            this.item.city = data.localidade;
            this.item.uf = data.uf;
          })
          .catch((error) => console.log(error));
      }
    },
    clearForm() {
      this.item = {
        cpf: "",
        full_name: "",
        email: "",
        cellphone: "",
        address: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        cep: "",
      };
    },
    submitCreate() {
      this.$v.item.$touch();
      this.submitted = true;

      if (!this.$v.item.$error) {
        this.$store
          .dispatch("Customer/create", this.item)
          .then((res) => {
            if (res.msg) {
              this.notifyDefault("error", res.msg);
            } else {
              this.clearForm();
              this.$v.item.$reset();
              this.notifyDefault("success");
              this.$router.push({
                name: 'customers-edit',
                params: { uuid: res.uuid },
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      } else {
        this.submitted = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  mounted() {
    this.$root.$on("open:modal-create-customer", () => {
      this.$bvModal.show("modal-create-customer");
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-customer");
  }
}
</script>